@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';
@use './styles/styles-palette';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.projectatlas.app/content/static/fonts/inter/v1/inter.css');
@import url('https://cdn.projectatlas.app/content/static/fonts/materialicon/v1/materialicon.css');

@layer {
	.pb-safe {
		padding-bottom: max(1rem, calc(env(safe-area-inset-bottom) + 1rem));
	}

	.pr-safe {
		padding-right: max(1rem, env(safe-area-inset-right));
	}

	.pl-safe {
		padding-left: max(1rem, env(safe-area-inset-left));
	}

	.px-safe {
		padding-right: max(1rem, env(safe-area-inset-right));
		padding-left: max(1rem, env(safe-area-inset-left));
	}

	.pt-safe {
		padding-top: max(1rem, env(safe-area-inset-top));
	}

	// Used by the Navbar
	.pt-safe-max {
		padding-top: max(0.5rem, env(safe-area-inset-top));
	}

	.mt-safe-toast {
		margin-top: max(1rem, calc(env(safe-area-inset-top) + 0.5rem)) !important;
	}

	.px-safe-containers {
		padding-right: max(1rem, env(safe-area-inset-right));
		padding-left: max(1rem, env(safe-area-inset-left));

		@media (min-width: theme('screens.md')) {
			padding-right: max(2rem, env(safe-area-inset-right));
			padding-left: max(2rem, env(safe-area-inset-left));
		}
	}
}

/* You can add global styles to this file, and also import other style files */
html,
body {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-family: 'Inter', sans-serif;
	@apply bg-yuno-blue-gray-800 text-yuno-blue-gray-600;
}

*:not(input):not(select):not(code):not(textarea):not([contenteditable]) {
	webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

mgl-map {
	.maplibregl-ctrl-attrib {
		display: none !important;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@apply text-yuno-blue-gray-700;
}

h1 {
	font-size: 1.5rem;
	font-weight: 600;
}

h2 {
	font-size: 1.25rem;
	font-weight: 500;
}

h3 {
	font-size: 1.125rem;
	font-weight: 500;
}

h4 {
	font-size: 1rem;
	font-weight: 500;
}

h5 {
	font-size: 0.875rem;
	font-weight: 500;
}

h6 {
	font-size: 0.75rem;
	font-weight: 400;
}

p {
	font-size: 1rem;
	font-weight: 400;
	line-height: 150%;
	/* 1.5rem */
}

router-outlet {
	display: none;
}

//html {
//	font-size: 16px;
//
//	--textfield-mobile-size: calc(100vh / 6);
//	--textfield-padding: 0;
//
//	--colors-additional: #3498db;
//
//	--colors-button: #3498db;
//	--colors-button-font: #fff;
//
//	--colors-navbar-active: #e9edef;
//	--colors-navbar-active-font: rgba(28, 42, 57, 0.95);
//	--colors-navbar-background: rgb(0, 160, 237);
//	--colors-navbar-font: rgba(28, 42, 57, 0.65);
//
//	--colors-location-marker: #3498db;
//	--colors-measure: #000;
//	--colors-panoramas: #3498db;
//	--colors-panoramas-cluster-count: #fff;
//
//	--colors-states-background: #3498db;
//	--colors-states-font: #fff;
//
//	--colors-text-heading: #394558;
//	--colors-text-link: #394558;
//}

$ui-scrollbar-front: #cdcdcd;
$ui-scrollbar-back: #f0f0f0;

/* Firefox Scrollbar based on new CSS module */
* {
	scrollbar-width: thin;
	scrollbar-color: $ui-scrollbar-front $ui-scrollbar-back;
}

/* Webkit scrollbar Safari/Chrome */
* {
	&::-webkit-scrollbar {
		background: $ui-scrollbar-back;
		width: 8px;
		height: 15px;
	}

	&::-webkit-scrollbar-thumb {
		background: $ui-scrollbar-front;
	}
}
